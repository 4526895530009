<template>
    <div v-if="widget">
        <loader :loading="loading" :backdrop="true" />
        <!-- Widget components are dynamically registered in main.js -->
        <component
            v-if="widgetComponent"
            :is="widgetComponent"
            @save-page-widget="createPageWidget"
        ></component>
    </div>
</template>

<script>
    import Auth from "@/mixins/Auth";
    import Loader from "@/components/ui/Loader";
    import { mapGetters, mapActions } from "vuex";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import EventBus from "@/utils/EventBus";

    export default {
        mixins: [ ModalNavigation, Auth ],
        components: { Loader },
        data: () => {
            return {
                websiteId: null,
                page: null,
                widget: null,
                pageWidget: null,
                widgetValues: [],
                loading: false,
            }
        },
        computed: {
            ...mapGetters({
                getPageBySlugAndWebsiteId: 'pages/getPageBySlugAndWebsiteId',
                getWidgetById: 'widgets/getWidgetById',
            }),
            widgetComponent() {
                return () => import('@/components/cms/widgets/' + this.widget.component);
            }
        },
        methods: {
            ...mapActions({
                addPageWidgetToStore: 'pages_widgets/addPageWidget',
                notifySuccess: 'alerts/notifySuccess',
            }),
            createPageWidget(payload) {
                EventBus.emit('disable-primary-footer-button');
                
                let data = {
                    customers_id: this.customerId,
                    websites_id: this.websiteId,
                    website_pages_name: this.page.website_pages_name,
                    widgets_id: this.widget.id,
                    order_on_page: 1,
                };

                let params = {
                    websiteId: this.websiteId,
                    pageName: this.page.website_pages_name,
                    data: data
                };

                this.loading = true;

                this.$cmsDataProvider.create('createPageWidget', params).then( (response) => {
                    this.pageWidget = response;
                    let order = 1;
                    for (let [key, value] of Object.entries(payload)) {
                        this.widgetValues.push({
                            customers_id: this.customerId,
                            websites_id: this.websiteId,
                            website_pages_name: this.page.website_pages_name,
                            widgets_id: this.widget.id,
                            field_name: key,
                            field_value: value,
                            website_pages_widgets_id: this.pageWidget.id,
                            order_on_page: order
                        });
                        order++;
                    }

                    params = {
                        websiteId: this.websiteId,
                        pageWidgetId: this.pageWidget.id,
                        data: {
                            widgetValues: this.widgetValues
                        }
                    };

                    this.$cmsDataProvider.create('savePageWidgetValues', params).then( () => {
                        this.notifySuccess('The page module was added successfully');
                        this.$cmsDataProvider.getOne('pageWidget', { websiteId: this.websiteId, pageName: this.page.website_pages_name, id: this.pageWidget.id })
                        .then( (response) => {
                            this.addPageWidgetToStore(response);
                            this.loading = false;
                            this.redirect('pages.show', {websiteId: this.websiteId, pageSlug: this.page.url_alias});
                        })
                    });
                });
            }
        },
        mounted() {
            this.websiteId = this.$route.params.websiteId;
            this.page = this.getPageBySlugAndWebsiteId(this.$route.params.pageSlug, this.websiteId);
            this.widget = this.getWidgetById(this.$route.params.widgetId);
            this.setActiveModalTitle(this.widget.name);
        }
    }
</script>